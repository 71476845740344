import React from "react";
import { Trans, t } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";
import LandingLayout from "../../components/landings/LandingLayout";
import useIsLanding from "../../hooks/useIsLandingHook";

const content = () => [
  {
    title: <Trans>Controller for the processing of personal data</Trans>,
    description: (
      <Trans>
        The controller for the processing of personal data is Allfunds Bank,
        S.A.U. as head office of the Allfunds Bank Group including its
        subsidiaries, branches and representative offices (hereinafter,
        "Allfunds" or the "Group"), located at Calle de los Padres Dominicos 7,
        28050 - Madrid, Spain.
      </Trans>
    ),
  },
  {
    title: <Trans>Purposes and lawfulness of Allfunds</Trans>,
    description: (
      <>
        <Trans>
          In compliance with the provisions of the applicable regulations on
          data protection, and, in particular, of Regulation (EU) 2016/679, of
          the European Parliament and of the Council of 27 April 2016, on the
          protection of natural persons with regard to the processing of
          personal data and on the free movement of such data, (hereinafter
          "GDPR"), Allfunds informs that your data will be processed for the
          following purposes:
        </Trans>
        <ul>
          <li style={{ marginBottom: 12 }}>
            <Trans>
              Management of the request made by the user Allfunds will perform
              the necessary data processing to manage the request made by the
              user. In this way, Allfunds will process data such as your name,
              surname, e-mail, telephone number, comments, position and entity,
              as well as information regarding the type of request. Allfunds
              will process this data based on the consent shown by the user when
              contacting Allfunds. Allfunds will communicate the user's data to
              those entities of the Allfunds Group or collaborating third
              parties for the attention of requests for information that the
              user demands. In this sense, such communication could involve an
              international transfer of data to a country that does not offer
              the same level of data protection as those located in the European
              Union. In these cases, Allfunds guarantees that the international
              transfer will be carried out applying the guarantees and
              obligations established by the GDPR.
            </Trans>
          </li>
          <li>
            <Trans>
              Technology control of the website Allfunds will perform those
              actions to monitor and control the use of the website in order to
              prevent and detect any fraudulent use. This processing is
              necessary to comply with those legal obligations established by
              the applicable regulations regarding services of the information
              society and electronic commerce, in order to avoid, prevent and /
              or detect any unauthorized access, alteration or loss of that
              information relating to third parties other than the user that may
              generates for Allfunds any type of liability.
            </Trans>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: <Trans>Storage of the information</Trans>,
    description: (
      <Trans>
        The personal data to which Allfunds have access will be processed during
        the necessary time to attend the request. In this sense, Allfunds may
        keep personal data of the user, duly blocked, during the applicable
        period of actions that may arise from the relationship maintained with
        the interested party or to attend the requirements of public
        authorities, in accordance with applicable regulations in each case.
      </Trans>
    ),
  },
  {
    title: <Trans>Communication of the processed personal data</Trans>,
    description: (
      <>
        <Trans>Allfunds will only communicate user data to:</Trans>
        <ul>
          <li>
            <Trans>
              Public bodies and institutions to which it is legally obliged to
              provide them (Public Administration, Courts and Courts ...).
            </Trans>
          </li>
          <li>
            <Trans>
              In the event of requests that affect any entity, subsidiary,
              branch or representative office of the Allfunds Group, Allfunds
              may communicate this information to the entity of the group or
              analogous affected by the request for the correct handling of the
              request.
            </Trans>
          </li>
          <li>
            <Trans>
              Allfunds has the collaboration of some third party service
              providers who have access to user personal data as result of the
              provision of services. Allfunds preselects such service providers
              based on compliance criteria in terms on data protection, has
              signed with all of them agreements for the provision of such
              services, including aspects related to data protection and control
              that these providers comply with their obligations in this matter.
            </Trans>
          </li>
        </ul>
      </>
    ),
  },
  {
    title: <Trans>Data subjects' rights on data protection</Trans>,
    description: (
      <Trans>
        The user mays exercise, if desired, his rights to access, rectification,
        erasure, restriction of processing, object, data portability and not to
        be the subject of an individual decision based solely on an automated
        processing, by sending an email to the address dpo@allfunds.com.
      </Trans>
    ),
  },
  {
    title: <Trans>Possible claims of users</Trans>,
    description: (
      <Trans>
        The user who considers that their data protection rights have been
        infringed or has any claim regarding their personal information may
        contact the Data Protection Officer of the Allfunds Group
        (dpo@allfunds.com). In any case, the data subject may always contact the
        Spanish Data Protection Agency, or another competent authority regarding
        data protection in accordance with the provisions of the applicable
        legislation.
      </Trans>
    ),
  },
  {
    title: <Trans>Security</Trans>,
    description: (
      <Trans>
        Allfunds Bank, S.A.U. has adopted the technical and organisational
        measures needed to maintain the required level of data security and
        integrity according to the nature of the personal data processed and the
        circumstances of the processing, in order to avoid, insofar as possible
        and in all events according to the state of the art, the alteration,
        loss or unauthorised processing of or access to the data.
      </Trans>
    ),
  },
  {
    title: <Trans>Cookies</Trans>,
    description: (
      <Trans>
        The website may use cookies, which are small data files that are
        generated in the user or customer's computer to allow our systems to
        recall the chosen language and portal, along with other navigation
        preferences or characteristics of the user from his or her first
        session. These cookies are neither invasive nor harmful and they contain
        no personal data, as their sole function is to personalise the user's
        navigation as described above. In accordance with current regulations,
        Allfunds Bank S.A.U. requests your permission to manage the use of
        Cookies. If you continue browsing Allfunds Bank S.A.U. website, without
        explicitly rejecting the use of Cookies, we deem that you consent to the
        use of Cookies by Allfunds Bank S.A.U.
      </Trans>
    ),
  },
  {
    title: <Trans>Links from and to other pages</Trans>,
    description: (
      <Trans>
        The website may include links to and from other websites of third
        parties over which Allfunds Bank, S.A.U. has no control. Allfunds Bank,
        S.A.U. disclaims all liability for information contained on other
        websites. Any information or opinions accessed through links to third
        party websites will be the responsibility of the relevant provider only
        and may not necessarily be shared by Allfunds Bank, S.A.U.
      </Trans>
    ),
  },
];

function PrivacyPolicyPage() {
  const isLanding = useIsLanding();
  const Layout = isLanding ? LandingLayout : NewLayout;

  return (
    <Layout
      {...(isLanding ? {
        seoTitle: t`Privacy Policy`,
      } : {
        byDefault: true,
        apps: [{ label: <Trans>Privacy Policy</Trans>, path: "/legal-advice/cookies-policy" }],
      })}
    >
      <Container pb={{ xs: 4, sm: 8 }}>
        <Typography mt={3} fontSize={36} fontWeight={700}>
          <Trans>Privacy Policy</Trans>
        </Typography>
        <Box>
          {content().map(({ title, description }, key) => (
            <Box key={key} mt={key === 0 ? 1 : 3}>
              <Typography variant="h4" mb={1}>{title}</Typography>
              <Typography>{description}</Typography>
            </Box>
          ))}
        </Box>
      </Container>
    </Layout>
  );
}

export default PrivacyPolicyPage;
